/* You can add global styles to this file, and also import other style files */


// Icons
@import url("https://i.icomoon.io/public/5287269068/ngx-feng/style.css");

// Fonts
// Roundkey
@font-face {
  font-family: "Roundkey bold";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+Bold.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Roundkey bold oblique";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+BoldOblique.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Roundkey extra light";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+ExtraLight.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Roundkey light";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+Light.otf")
  format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Roundkey light oblique";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+LightOblique.otf")
  format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Roundkey medium";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+Medium.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Roundkey medium oblique";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+MediumOblique.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Roundkey oblique";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+Oblique.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: "Roundkey regular";
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Roundkey/Roundkey+Regular.otf") format("truetype");
  font-style: normal;
}
// Titillium Web
@font-face {
  font-family: "Titillium Web Black";
  src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.ttf") format("truetype"),
    url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.woff") format("woff"),
    url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.woff2") format("woff2");
}


@font-face {
  font-family: Apocalypse Grunge;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Apocalypse+Grunge/Apocalypse+Grunge.ttf") format("truetype");
  font-style: normal;
  font-weight: 100;
}

// Metropolis
@font-face {
  font-family: Metropolis;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Metropolis/Metropolis-Regular.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Metropolis-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Metropolis/Metropolis-Bold.otf") format("truetype");
  font-style: bold;
  font-weight: 800;
}

// D-DIN
@font-face {
  font-family: D-DIN;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/d-din/D-DIN.otf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: D-DIN-bold;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/d-din/D-DIN-Bold.otf") format("truetype");
  font-style: bold;
  font-weight: 900;
}

@font-face {
  font-family: D-DIN-italic;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/d-din/D-DIN-Italic.otf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
//Unisans
@font-face {
  font-family: Uni-Sans;
  src: url('https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/UniSans/Uni+Sans+Book.otf') format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Uni-Sans-italic;
  src: url('https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/UniSans/Uni+Sans+Book+Italic.otf') format("truetype");
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Uni-Sans-bold;
  src: url('https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/UniSans/Uni+Sans+Bold.otf') format("truetype");
  font-style: bold;
  font-weight: 700;
}

@font-face {
  font-family: Uni-Sans-bold-italic;
  src: url('https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/UniSans/Uni+Sans+Bold+Italic.otf') format("truetype");
  font-style: normal;
  font-weight: 500;
}

// Montserrat
@font-face {
  font-family: Montserrat;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Montserrat;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}


@font-face {
    font-family: Montserrat-Light;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}

@font-face {
    font-family: Montserrat-Medium;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
}
@font-face {
    font-family: Montserrat-bold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-style: bold;
    font-weight: 900;
}
@font-face {
    font-family:Montserrat-SemiBold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 700;
}

//USBlaak

@font-face {
  font-family: UsBlaakMedium;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/US-Blaak/US+Blaak+Black.otf") format("truetype");
}

// Montserrat
@font-face {
    font-family: IntegralCF;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Integral-font/IntegralCF-Regular.otf") format("truetype");
}


@font-face {
    font-family: IntegralCF-Bold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Integral-font/IntegralCF-Bold.otf") format("truetype");
    font-style: bold;
    font-weight: 900;
}
@font-face {
    font-family:IntegralCF-SemiBold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Integral-font/IntegralCF-Medium.otf") format("truetype");
    font-weight: 700;
}

// Adineue PRO
@font-face {
    font-family: AdineuePro;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/adineue+PRO/adineue+PRO.ttf") format("truetype");
}
@font-face {
    font-family: AdineuePro-Bold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/adineue+PRO/adineue+PRO+Bold.ttf") format("truetype");
    font-style: bold;
    font-weight: 900;
}

// BFR Sans
@font-face {
  font-family: BFRSans;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/BFR+Sans/BFRSans-Regular.otf") format("truetype");
}
@font-face {
  font-family: BFRSans-SemiBold;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/BFR+Sans/BFRSans-SemiBold.otf") format("truetype");
  font-weight: 700;
}
@font-face {
  font-family: BFRSans-Bold;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/BFR+Sans/BFRSans-Bold.otf") format("truetype");
  font-style: bold;
  font-weight: 900;
}
@font-face {
  font-family: TuskerGrotesk;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/TUSKER+GROTESK/TuskerGrotesk-6700Bold.ttf") format("truetype");
  font-style: bold;
  font-weight: 400;
}

@font-face {
  font-family: Barlow-Ligth;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Barlow/Barlow-Light.ttf") format("truetype");
}
@font-face {
  font-family: Barlow-Regular;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Barlow/Barlow-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Barlow-Medium;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Barlow/Barlow-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Barlow-SemiBold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Barlow/Barlow-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: Barlow-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Barlow/Barlow-Bold.ttf") format("truetype");
}


// LedDotMatrix
@font-face {
  font-family: LedDotMatrix;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/LedDotMatrix/LEDDot-Matrix.ttf") format("truetype");
}

// Barlow
@font-face {
  font-family: BarlowSemiCondensedBold;
  src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/Barlow Semi Condensed-Bold.ttf") format("truetype");
}
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700;800;900&display=swap');

// Nunito Sans

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,500&display=swap');

// Pridi
@import url('https://fonts.googleapis.com/css2?family=Pridi:wght@200;300;400;500;600;700&display=swap');

// Fluminense Fonts
@font-face {
  font-family: CityDMed;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/CityDMed.ttf") format("truetype");
}
@font-face {
  font-family: CityDBol;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/CityDBol.ttf") format("truetype");
}
@font-face {
  font-family: GillSans;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans.otf") format("truetype");
}
@font-face {
  font-family: GillSansBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: GillSansMT;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT.ttf") format("truetype");
}
@font-face {
  font-family: GillSansMTBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT-Bold.ttf") format("truetype");
  font-style: bold;
  font-weight: 700;
}
@font-face {
  font-family: GillSansMTItalic;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT-Italic.ttf") format("truetype");
}
@font-face {
  font-family: GillSansMTItalic;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT-Italic.ttf") format("truetype");
}
@font-face {
  font-family: BebasNeue;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/BebasNeue.otf") format("truetype");
}
@font-face {
  font-family: RidleyGrotesk-Medium;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/RidleyGrotesk-Medium.otf") format("truetype");
}
@font-face {
  font-family: RidleyGrotesk-SemiBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/RidleyGrotesk-SemiBold.otf") format("truetype");
}
@font-face {
  font-family: RidleyGrotesk-ExtraBold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/RidleyGrotesk-ExtraBold.otf") format("truetype");
}
@font-face {
  font-family: VarsityTeam-Bold;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/VarsityTeamBold-6AXq.otf") format("truetype");
}
@font-face {
  font-family: Ernesto;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/Ernesto.otf") format("truetype");
}
@font-face {
  font-family: Gobold-Extra2;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/Gobold+Extra2.otf") format("truetype");
}
@font-face {
  font-family: BwQuintaPro-Medium;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/BwQuintaPro-Medium.otf") format("truetype");
}
@font-face {
  font-family: SportsWorld-Regular;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/SportsWorld/Sports+World-Regular.otf") format("truetype");
}
@font-face {
  font-family: MarginMVB-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Margin+MVB+Font/Margin+MVB+Bold.otf") format("truetype");
}
@font-face {
  font-family: MarginMVB-Medium;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Margin+MVB+Font/Margin+MVB+Medium.otf") format("truetype");
}


// Cruzeiro Fonts
@font-face {
  font-family: Heebo-Regular;
  src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Heebo-Regular.ttf") format("truetype");
}
@font-face {
  font-family: Heebo;
  src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Heebo-Medium.ttf") format("truetype");
}
@font-face {
  font-family: Heebo-Bold;
  src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Heebo-Bold.ttf") format("truetype");
}
@font-face {
  font-family: ApexMk3;
  src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Apex+Mk3-Medium.otf") format("truetype");
}
@font-face {
  font-family: ApexMk3-ExtraLight;
  src: url("https://cruzeiro.s3.amazonaws.com/prod/assets/fonts/Apex+Mk3-ExtraLight.otf") format("truetype");
}
@font-face {
  font-family: Allotrope-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Allotrope/allotrope-bold.otf") format("truetype");
}

// Cerro Fonts
@font-face {
  font-family: Athletic-Pro;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/AthleticPro/Athletic+Pro+Regular.otf") format("truetype");
}
@font-face {
  font-family: Athletic-Pro-Semibold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/AthleticPro/Athletic+Pro+Semibold.otf") format("truetype");
  font-style: bold;
  font-weight: 700;
}
@font-face {
  font-family: Athletic-Pro-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/AthleticPro/Athletic+Pro+Bold.otf") format("truetype");
  font-style: bold;
  font-weight: 900;
}
@font-face {
  font-family: Athletic-Pro-Black;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/AthleticPro/Athletic+Pro+Black.otf") format("truetype");
  font-style: bold;
  font-weight: 1000;
}
@font-face {
  font-family: Athletic-Pro-Light;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/AthleticPro/Athletic+Pro+Light.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Athletic-Pro-Thin;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/AthleticPro/Athletic+Pro+Thin.otf") format("truetype");
  font-style: normal;
}

//Corinthians Fonts

@font-face {
  font-family: Gotham-Light;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Gotham/Gotham-Light.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Gotham-Book;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Gotham/Gotham-Book.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Gotham-Medium;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Gotham/Gotham-Medium.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Gotham-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Gotham/Gotham-Bold.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Gotham-Ultra;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Gotham/Gotham-Ultra.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Steelfish-Medium;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/steelfish/steelfish+rg.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Steelfish-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/steelfish/steelfish+bd.otf") format("truetype");
  font-style: normal;
}
@font-face {
  font-family: Steelfish-Extra-Bold;
  src: url("https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/steelfish/steelfish+eb.otf") format("truetype");
  font-style: normal;
}

// Botafogo Icons
// @import url("https://i.icomoon.io/public/5287269068/st-botafogo-web/style.css");
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap');


@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;700&family=Barlow+Semi+Condensed:wght@400;700&family=Titillium+Web:wght@200;400;500;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

//Kufam
@import url('https://fonts.googleapis.com/css2?family=Kufam:wght@400;700&display=swap');

//Tektur
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;500;600;700;800;900&display=swap');

//Figtree
@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

// Angular Material Theme
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css';

//Phudu - Cruzeiro New
@import url(https://fonts.googleapis.com/css2?family=Phudu:wght@400;700&family=Figtree:wght@400;700&display=swap);

//TekturNarrow - Cruzeiro New
@font-face {
  font-family: 'TekturNarrow';
  src: url(https://cruzeiro.s3.amazonaws.com/TekturNarrow-Bold.2ce021746c68e8cb46cd.ttf) format('truetype');
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

//Devant Horgen
@font-face {
  font-family: Devant Horgen;
  src: url('https://ngx-feng.s3.sa-east-1.amazonaws.com/fonts/Devant+Horgen/DevantHorgen-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

// Libs imports always last
@import '~@fengbrasil/ngx-fengst-ui/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-auth/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-layout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-home/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-events/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-account/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-exclusive-content/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-contact/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-partner/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-experience/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-checkout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-security/assets/dist/styles';